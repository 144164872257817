import React, { useEffect, useState } from "react";
import "./style.css";
import circleCloseIcon from ".././../../assets/icons/circleCloseIcon.svg";
import exitModalImage from "../../../assets/exitModalImage.jpg";
import exitModalImageMobile from "../../../assets/exitModalImageMobile.jpg";
import { Button, Form, Spinner } from "react-bootstrap";
import copy from "copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { _ToggleWelcomeModalOnCartExit } from "../../../redux/actions/product";
import { toast } from "react-toastify";
import { subscribeForFirstOrderToGetDiscount } from "../../../https/productsScreenRequests";
import {
  errorRequestHandel,
  isValidEmailAddress,
} from "../../../utils/helperFile";

const PopUpOnCartExit = () => {
  const dispatch = useDispatch();
  const _isWelcomeModelOpen = useSelector(
    (state) => state._products.isWelcomeModelOpen
  );
  // const _isWelcomeModelOpen = true;
  const isLoggedIn = useSelector((state) => state._auth.isAuthenticated);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  let source_id = 1;

  useEffect(() => {
    const timer = setTimeout(() => {
      const hasShownModal = sessionStorage.getItem("hasShownWelcomeModal");
      if (!hasShownModal && !_isWelcomeModelOpen && !isLoggedIn) {
        dispatch(_ToggleWelcomeModalOnCartExit(true));
        sessionStorage.setItem("hasShownWelcomeModal", "true");
      }
    }, 5000); // Show after 5 seconds

    return () => clearTimeout(timer);
  }, [dispatch, _isWelcomeModelOpen, isLoggedIn]);

  const onSubmitEmailAddressToGetDiscount = async () => {
    try {
      const isEmailValid = isValidEmailAddress(email);
      if (email === "" || email == undefined) {
        setEmailError("Email is required");
        return;
      }
      if (email.includes(" ")) {
        setEmailError("Email cannot contain spaces");
        return;
      }
      if (!isEmailValid) {
        setEmailError("Email address is not valid");
        return;
      }

      setLoading(true);
      const payload = {
        email: email,
        source_id: source_id,
      };
      const response = await subscribeForFirstOrderToGetDiscount({
        payload: payload,
      });
      if (response.status === 200) {
        setLoading(false);
        toast.success("Email has been sent successfully");
        setEmail("");
        dispatch(_ToggleWelcomeModalOnCartExit(false));
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      errorRequestHandel({ error: error });
    }
  };

  const closeModal = () => {
    dispatch(_ToggleWelcomeModalOnCartExit(false));
  };

  const isMobileScreen = window.innerWidth <= 568;

  if (!_isWelcomeModelOpen) return null;

  return (
    <div className="pop_up_on__cartexit-modal">
      <div className="pop_up_on__cartexit-modal-content">
        <div className="pop_up_on__cartexit__body">
          <img
            src={isMobileScreen ? exitModalImageMobile : exitModalImage}
            alt="modal-image"
            className="pop_up_on__image"
          />
          <div className="pop_up_on__cartexit__left">
            <div className="pop_up_on__cartexit-close" onClick={closeModal}>
              <img src={circleCloseIcon} alt="close" />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 5,
                width: "80%",
              }}
            >
              <p className="pop_up__welcome_text">UNLOCK</p>

              <div className="pop_up__welcomecode_section">
                <span>10% OFF</span>
              </div>
              <p className="pop_up__order_text">ON YOUR FIRST ORDER</p>
            </div>
            <div className="pop_up__email_and_button_section">
              <div className="pop_up__email_field">
                <input
                  placeholder="Email*"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => setEmailError("")}
                />
              </div>
              {emailError.length > 0 && (
                <span className="pop_up_email_error">{emailError}</span>
              )}
              <div
                className="pop_up__button_section"
                onClick={onSubmitEmailAddressToGetDiscount}
              >
                {loading ? (
                  <Spinner animation="border" size="md" role="status" />
                ) : (
                  "Yes, Please"
                )}
              </div>
            </div>
          </div>
          <div className="pop_up_on__cartexit__right"></div>
        </div>
      </div>
    </div>
  );
};

export default PopUpOnCartExit;
