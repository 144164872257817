import React, { useCallback, useEffect, useState } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import crossIcon from "../../assets/icons/crossIcon.svg";
import defaultAvatar from "../../assets/icons/defaultAvatar.svg";
import viewFromIcon from "../../assets/icons/viewFrom.svg";
import viewitemsIcon from "../../assets/icons/viewitemsIcon.svg";
import socket from "../../socket/socket";
import moment from "moment/moment";

let counter = 0;
let threshold = getRandomThreshold();

function getRandomThreshold() {
  return Math.floor(Math.random() * 3) + 4; // Random number between 4 and 6
}

function getPurchaseMessage() {
  if (counter >= threshold) {
    counter = 0;
    threshold = getRandomThreshold();
    return "2 people purchased";
  } else {
    counter++;
    return "1 person purchased";
  }
}

const SalesViewNotification = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});

  const handleSocketEvent = useCallback((payload) => {
    setData(payload);
    setShow(true); // Show the pop-up when event received

    // Hide the pop-up after 10 seconds
    setTimeout(() => {
      setShow(false);
    }, 5000);
  }, []);

  useEffect(() => {
    socket.on("product-sold", (data) => handleSocketEvent(data));
    return () => {
      socket.off("product-sold", handleSocketEvent);
    };
  }, [handleSocketEvent]);

  return (
    <div className={`pop__container ${show ? "show" : "hide"}`}>
      <div className="pop__body">
        <div className="pop_avatar_section">
          <div className="pop_avatar">
            <img src={data?.product_cover_mage} />
          </div>
        </div>
        <div className="pop_detail_section">
          <div className="pop_detail__view_from">
            <div style={{ display: "flex" }}>
              <img src={viewitemsIcon} />
              <img src={viewFromIcon} />
            </div>
            <div style={{ display: "flex" }}>
              <p className="pop_item_title">
                <small
                  style={{ marginRight: "5px", color: "#888", fontSize: 10 }}
                >
                  {getPurchaseMessage()}
                </small>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#888888",
                    fontSize: 11,
                  }}
                >
                  {data?.proiduct_name}
                </span>
              </p>
            </div>
          </div>
          <small className="pop_duration">
            {/* {moment(data?.time).fromNow()} */}
            {Math.floor(Math.random() * 59) + 1} minutes ago
          </small>
          <small
            className="pop_view__link"
            onClick={() => {
              setShow(false);
              navigate(`/products/${data?.identifier}`);
            }}
          >
            View Product
          </small>
        </div>
        <div className="pop_close__section" onClick={() => setShow(false)}>
          <img src={crossIcon} alt="close" />
        </div>
      </div>
    </div>
  );
};

export default SalesViewNotification;
